export function removeItemOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export function deleteItem(dict, key) {
    delete dict[key];
    return dict;
}
