//import logo from './logo.svg';
import React from 'react';
/*import ReactDOM from 'react-dom';
import ReactDOM1 from 'react-dom/client';*/
import './App.css';
import {database} from './database.js'
import {removeItemOnce} from "./general";
import {root} from './index.js'

let context = {
    currentDatabase: null,
    admin: false
}
let temp;
export function clearTemp() {
    temp = {
        itemData: [],
        database: {},
        result: null,
        apiData: null
    }
    return temp
}
temp = clearTemp();


function contentBlockItemClicked(Event) {
    try {
        // will work if not at end of list
        console.log(context.currentDatabase[Event.target.value]) // log clicked button

        // create temp.itemData
        temp.itemData = []
        context.currentDatabase[Event.target.value].forEach(item => {
            temp.itemData.push(item)
        })
        // operate on temp.itemData
        temp.itemData = removeItemOnce(temp.itemData, temp.itemData[0])

        // render new view
        root.render(
            <div className="App">
                <MainHeader header={context.currentDatabase.config.root_title}/>
                <ViewDesc desc={context.currentDatabase[Event.target.value][0]}/>
                <ContentBlocks
                    items={temp.itemData}
                    callback={contentBlockItemClicked}
                />
            </div>
        )

    } catch (e) {
        // if at end of directory tree
        console.log(e)
        // render student
        temp.name = Event.target.value

        root.render(<StudentPage name={temp.name}/>)

    }

}

class LoadingScreen extends React.Component {
    render() {
        return (
            <div className="cell">
                <div className="pl pl-fade"></div>
                <div className="pl-name">Loading...</div>
            </div>
        )
    }
}

function studentPageAdminEdit(Event) {
    console.log(Event)
    temp.apiResult1 = StudentPage.queryAPI(temp.name, Event.target.value, document.getElementById(Event.target.value).value)
    root.render(<StudentPage name={temp.name}/>)
    alert("Change Recorded")
}

function signOut() {
    temp.apiResult1 = StudentPage.queryAPI(temp.name, "signOut", null)
    root.render(<StudentPage name={temp.name}/>)
    alert(`${temp.name} Checked Out!`)
}

class StudentPage extends React.Component {

    static queryAPI(name, method, newValue) {

        temp.data = {
            name: name, //this.props.item
            config: context.currentDatabase.config,
            method: method, //this.props.mode
            newValue: newValue ? newValue : null //this.props.newValue
        }
        temp.xhr = new XMLHttpRequest();
        temp.xhr.open("POST", "https://api.techmandev.com/clients_cbs_signout-portal/", false);
        temp.xhr.setRequestHeader('Content-Type', 'application/json');
        temp.xhr.send(JSON.stringify(temp.data));
        temp.xhr.onload = function () {
            //console.log("HELLO")
            //console.log(this.responseText);
            return JSON.parse(this.responseText);
            //console.log(data);
        }
        //console.log(temp.xhr.responseText)

        console.log(JSON.parse(temp.xhr.responseText))
        return JSON.parse(temp.xhr.responseText)
    }


    render() {

        temp.apiResult2 = StudentPage.queryAPI(this.props.name, "query", null)
        return (
            <div>
                <MainHeader header={context.currentDatabase.config.root_title}/>

                <h1>{temp.apiResult2.details.name}</h1>

                <button onClick={signOut}>Sign Out</button>

                <h2>Balance Due</h2>

                {context.admin ?
                    <div>
                        <input placeholder={temp.apiResult2.balance.balanceDue.status ? temp.apiResult2.balance.balanceDue.amount : 0} id="adminBalanceUpdate" type="number"/>
                        <button value={"adminBalanceUpdate"} onClick={studentPageAdminEdit}>Save Change</button>
                    </div> :
                    <div>
                        <code>{temp.apiResult2.balance.balanceDue.status ? "$" + temp.apiResult2.balance.balanceDue.amount : "$0"}</code>
                    </div>
                }

                <h2>Prepaid Until Date</h2>
                {context.admin ?
                    <div>
                        {!temp.apiResult2.balance.credit.status && <p>No credits available</p>}
                        <input placeholder={temp.apiResult2.balance.credit.status ? temp.apiResult2.balance.credit.weekCount : "Example: " + temp.apiResult2.today} id="adminCreditUpdate"/>
                        <button value={"adminCreditUpdate"} onClick={studentPageAdminEdit}>Save Change</button>
                    </div> :
                    <div>
                        <code>{temp.apiResult2.balance.credit.status ? "Prepaid until " + temp.apiResult2.balance.credit.weekCount : "No credits available"}</code>
                    </div>
                }

                {context.admin &&
                    <div>
                        <h2>Last 20 SignOuts</h2>
                        <ul>
                            {temp.apiResult2.activity.map(item => {
                                return <li>{item}</li>
                            })}
                        </ul>
                    </div>
                }


            </div>
        )


    }
}

function returnHome() {
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );

    temp = clearTemp();
}

class HomeButton extends React.Component {

    render() {
        return (
            <div>
                <button
                    onClick={returnHome}
                >
                    Return Home
                </button>
            </div>
        )
    }


}

class MainHeader extends React.Component {
    render() {
        return (
            <header>
                {!this.props.noHome && <HomeButton/>}
                {context.admin && <p>ADMIN MODE ENABLED</p>}
                <h1>{this.props.header}</h1>
            </header>
        )
    }

}

class ViewDesc extends React.Component {

    render() {
        return (
            <p>{this.props.desc}</p>
        )

    }

}

class ContentBlocks extends React.Component {


    generateItems() {
        temp.generateItems_t1 = this.props.items.map(item => (
            <button
                onClick={this.props.callback}
                key={"item-id" + item}
                value={item}
            >
                {item}
            </button>
        ))
        return temp.generateItems_t1
    }

    render() {
        return (
            <div>{this.generateItems()}</div>
        )
    }
}

async function initDatabase(Event) {

    root.render(<LoadingScreen />)
    await new Promise(r => setTimeout(r, 1000));
    context.currentDatabase = database[Event.target.value]
    root.render(<App/>)

}

function enableAdmin(Event) {
    if (Event.target.checked) {
        alert("Warning: Enabling Admin mode allows all student data to be viewed and edited. This will remain enabled until app is closed. Please use with caution.")
        context.admin = true;
    }
    else {
        context.admin = false;
    }
}

class RootSelect extends React.Component {
    render() {
        // generate _database temp var

        temp.database = {};
        Object.keys(context.currentDatabase).forEach(item => {
            temp.database[item] = context.currentDatabase[item]
        })
        delete temp.database["config"]
        console.log(context.currentDatabase)

        temp.App_t1 = (
            <div className="App">
                <MainHeader
                    header={context.currentDatabase.config.root_title}
                    noHome={true}
                />
                <ViewDesc desc={context.currentDatabase.config.root_message}/>
                <ContentBlocks
                    items={Object.keys(temp.database)}
                    callback={contentBlockItemClicked}
                />
            </div>
        )
        return temp.App_t1;


    }
}

class AdminSelect extends React.Component {

    render() {
        return (
            <div className="App">
                <MainHeader
                    header={"CBS Signout Portal"}
                    noHome={true}
                />
                <ViewDesc desc={"Admin Selection Page. Please select the Signout Profile you wish to use"}/>
                <ContentBlocks
                    items={Object.keys(database)}
                    callback={initDatabase}
                />
                <br/>
                <label >Enable Admin for this Launch</label>
                <input id="admin_checkbox" type="checkBox" onClick={enableAdmin}/>
            </div>
        );
    }
}

function App() {

    if (context.currentDatabase !== null) {
        return(<RootSelect />)
    } else {
        return(<AdminSelect />)
    }
}

export default App;
