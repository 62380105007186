export const database = {

    'SA - Summer Camp - Before Care': {
        "config": {
            "root_title": "CBS Summer Camp - Before Care",
            "root_message": "Please select your child's homeroom",
            "billing": {
                "rate": 5,
            }
        },
        "5R": [
            "Please select your child's name", // 1st item becomes message
            "John Smith",
            "Apple Brain"
        ],
        "5B": [
            "Please select your child's name",
            "John Smith",
            "Apple Brain"
        ],
        "4N": [
            "Please select your child's name",
            "John Smith",
            "Apple Brain",
            "Nana banna"
        ]
    },

    'SA - Summer Camp - Aftercare': {
        "config": {
            "root_title": "CBS Summer Camp - Aftercare",
            "root_message": "Please select your child's homeroom",
            "billing": {
                "rate": 5,
            }
        },
        "5R": [
            "Please select your child's name", // 1st item becomes message
            "John Smith",
            "Apple Brain"
        ],
        "5B": [
            "Please select your child's name",
            "John Smith",
            "Apple Brain"
        ],
        "4N": [
            "Please select your child's name",
            "John Smith",
            "Apple Brain",
            "Nana banna"
        ]
    },

    'SA - School - Aftercare': {
        "config": {
            "root_title": "CBS Aftercare",
            "root_message": "Please select your child's homeroom",
            "billing": {
                "rate": 5,
            }
        },
        "5R": [
            "Please select your child's name", // 1st item becomes message
            "John Smith",
            "Apple Brain"
        ],
        "5B": [
            "Please select your child's name",
            "John Smith",
            "Apple Brain"
        ],
        "4N": [
            "Please select your child's name",
            "John Smith",
            "Apple Brain",
            "Nana banna"
        ]
    },

}
