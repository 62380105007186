import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {clearTemp} from "./App";
import reportWebVitals from './reportWebVitals';
import {database} from './database.js'

export const root = ReactDOM.createRoot(document.getElementById('root'));
async function renderRoot(ms) {
    await new Promise(r => setTimeout(r, ms));

    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );
}
renderRoot(1000);
clearTemp();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
